import cx from "classnames";
import { Button as Btn, ButtonProps } from "@nextui-org/react";
import React, { FC } from "react";

interface Button extends ButtonProps {
  label: React.ReactNode;
}

export const Button: FC<Button> = ({
  className,
  color,
  onClick,
  radius,
  size,
  label,
  ...props
}) => {
  return (
    <Btn
      {...props}
      color={!color ? "primary" : color}
      className={cx("capitalize min-w-36", className)}
      radius={!radius ? "sm" : radius}
      size={size ?? "md"}
    >
      {label}
    </Btn>
  );
};
